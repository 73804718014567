import http from '../../../../utils/services/http'

export const list = (limit, page, filtros, basic) => {
  let url = '/api/clientes?page=' + page + '&limit=' + limit + filtros
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const find = (id) => {
  let url = `/api/clientes/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newCliente = (data) => {
  let url = `/api/clientes`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateCliente = (id, data, method = 'PUT') => {
  let url = `/api/clientes/${id}`
  let m = http.put
  if (method === 'PATCH') {
    m = http.patch
  }
  return m(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const remove = (id) => {
  let url = `/api/clientes/${id}`
  return http.delete(url)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const updateFotoCliente = (id, data) => {
  let url = `/api/clientes/${id}/photo`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const setup = (id) => {
  let url = `/api/clientes/${id}/setup`
  return http.post(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const setupLogBuffer = (id) => {
  let url = `/api/log/find`
  return http.get(url + `?entity=cliente&entityId=${id}&action=setup`)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const dashboardClientes = () => {
  let url = `/api/clientes/dashboard`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const uploadArquivo = (id, data) => {
  let url = `/api/clientes/${id}/arquivos`
  return http.post(url, data, {
    'timeout': 300000
    // headers: {'Content-Type': 'multipart/form-data'}
  })
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const downloadArquivo = (cliente, arquivo, inline = false) => {
  let url = `/api/clientes/${cliente.id}/arquivos/${arquivo}/download?`
  if (inline) {
    url = url + '&inline=true'
  }
  return http.get(url)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const deleteArquivo = (cliente, arquivo) => {
  let url = `/api/clientes/${cliente.id}/arquivos/${arquivo}/delete`
  return http.delete(url)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const rotinasAutomaticas = () => {
    let url = `/api/clientes/rotinasAutomaticas`
    return http.post(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const getIdByPersonId = (id) => {
    let url = `/api/clientes/getIdByPersonId/${id}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
